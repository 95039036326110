const firebaseConfig = {
    apiKey: "AIzaSyAW6HrRJWe6RYQYUP7JmksEvaC3Lz1ULOQ",
    authDomain: "haverford-engagement.firebaseapp.com",
    projectId: "haverford-engagement",
    storageBucket: "haverford-engagement.appspot.com",
    messagingSenderId: "679850731654",
    appId: "1:679850731654:web:f03a38dd359b2559894907",
    measurementId: "G-GJ6XFX5RGY"
  };

export default firebaseConfig;
