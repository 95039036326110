import { observer } from 'mobx-react';

import Section from '../Section';
import ContactForm from '../ContactForm/ContactForm';
import { ENGAGEMENT_LINK } from '../../config';

import { henshu, useHenshu } from '@strategies/henshu';

export default observer(function Contact() {
    const { bindTo } = useHenshu();
    return (
        <Section index={4} name="Contact">
           <div className="row">
                <ContactForm/>

                <div className="engagement-content">
                    
                    {/* <div className="inner-content">
                        <div className="engagement-blurb">
                            <henshu.h4 {...bindTo('engagement.title')} />
                            <henshu.p {...bindTo('Welcome.body')} />
                        </div>

                        <henshu.a
                            className="button with-arrow"
                            href={ENGAGEMENT_LINK}
                            {...bindTo('engagement.button')}
                        />
                    </div> */}
                   
                </div>
            </div>
        </Section>
    );
});
