import React from 'react';
import ReactDOM from 'react-dom/client';
import { HenshuApp } from '@strategies/henshu-app';

import App from './App';
import seeds from './seeds';
import firebaseConfig from './firebaseConfig';

ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <HenshuApp config={firebaseConfig} seeds={seeds} langs={['en']}>
            <App />
        </HenshuApp>
    </React.StrictMode>
);
