import { useCallback } from 'react';
import { observer } from 'mobx-react';
import { henshu, useHenshu } from '@strategies/henshu';
import { Anchor } from '@strategies/react-anchors';

import { MENU } from '../config';
import { useContext } from '../context';


export default observer(function Section({ children, index, name }) {
    const { update } = useContext();
    const { bindTo } = useHenshu();
    const id = index !== null ? MENU[index].href.replace('#', '') : '';

    const updateSection = useCallback((section) => {
        window.history.replaceState(null, window.location.hash, MENU[section].href);
        update({ section });
    }, [update]);

    return (
        <div className={`Section ${name}`}>
            <Anchor
                id={id}
                onTop={() => updateSection(index)}
                onOffscreen={(anchor) => anchor.top >= window.innerHeight && updateSection(index - 1)}
            />

            <div className="content">
                <henshu.h3 {...bindTo(`${name}.subtitle`)} />
                <henshu.h2 {...bindTo(`${name}.title`)} />
                {(name==="Resources") && <henshu.h4 {...bindTo(`${name}.description`)} />}

                {children}
            </div>
        </div>
    );
});
