import { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { useHenshuApp } from '@strategies/henshu-app';
import { henshu, useHenshu } from '@strategies/henshu';

import Section from '../Section';


export default observer(function Vision() {
    const [height, setHeight] = useState('20px');
    const { upload } = useHenshuApp().persist;
    const { bindTo } = useHenshu();

    const onImageRef = useCallback(ref => {
        if (ref) {
            const setDims = () => {
                const { width } = ref.getBoundingClientRect();
                setHeight(`${width / 1.47}px`);
            };

            (new ResizeObserver(setDims)).observe(ref);

            setDims();
        }
    }, []);

    return (
        <Section index={1} name="Vision">
            <henshu.richtext {...bindTo('Vision.body')} />

            <div className="row">
                <div className="col">
                    <div className="image-wrap" ref={onImageRef} style={{ height }}>
                        <henshu.img
                            style={{ height }}
                            get={() => bindTo('Vision.historical.image').get()}
                            set={async (t) => bindTo('Vision.historical.image').set(t ? await upload(t) : t)}
                        />
                    </div>

                    <henshu.h4 {...bindTo('Vision.historical.title')} />
                </div>

                <div className="col">
                    <div className="image-wrap" style={{ height }}>
                        <henshu.img
                            style={{ height }}
                            get={() => bindTo('Vision.present.image').get()}
                            set={async (t) => bindTo('Vision.present.image').set(t ? await upload(t) : t)}
                        />
                    </div>

                    <henshu.h4 {...bindTo('Vision.present.title')} />
                </div>
            </div>
        </Section>
    );
});
