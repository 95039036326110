/*
 * Configuration
 */

export const ENGAGEMENT_LINK = 'https://comap.sasaki.com/haverford';

export const FORMSPREE_URL = 'https://formspree.io/f/xknlewvz';

export const MOBILE_BREAKPOINT = 1200;

export const MENU = [
    { text: 'Welcome', href: '#welcome' },
    { text: 'About', href: '#vision' },
    { text: 'Process + Timeline', href: '#process' },
    { text: 'Resources + News', href: '#resources' },
    { text: 'Contact', href: '#share' },
];
