import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { AutoSizer } from 'react-virtualized';
import { FiChevronDown } from 'react-icons/fi';
import { Anchor } from '@strategies/react-anchors';
import { henshu, useHenshu } from '@strategies/henshu';

import Section from '../Section';

import { ENGAGEMENT_LINK } from '../../config';

import banner from '../../assets/Trimmed_AdobeExpress.mp4';


export default observer(function Welcome() {
    const { bindTo } = useHenshu();
    const [promptHidden, setPromptHidden] = useState(false);
    const videoRef = useRef();

    const [playing, setPlaying] = useState(true);

   
    return (
        <div className="Welcome-wrap">
            {/* <div className="video-wrap">

                <video autoPlay={true} muted={true} loop={true} className={"welcome-video"} type="video/mp4"  src={banner}/>
            </div> */}
            
            
            <Section name="Welcome" index={0}>
            
                <Anchor onChange={(anchor) => setPromptHidden(anchor.placement === 'offscreen')} />

                <div className="links">
                    <henshu.richtext {...bindTo('calendar.body')} />

                    <henshu.a
                        className="button with-arrow"
                        href={"#resources"}
                        {...bindTo('calendar.button')}
                    />
                </div>
                

                {/*}<henshu.p {...bindTo('Welcome.body')} />*/}

                {/* <div className='engagement-animation engage'>
                    <Callout term="animation.prompt1"/>
                    <Callout term="animation.prompt2"/>
                    <Pin className="live" alt={''} icon={sunIcon} term="animation.icon1" />
                    <Pin className="work" alt={''} icon={usersIcon} term="animation.icon2" />

                    <div className="connected-pins">
                        <AutoSizer>
                            {({ width, height }) => <>
                                <Pin className="walk" alt={''} icon={globeIcon} term="animation.icon3" />
                                <Pin className="entertainment" alt={''} icon={cameraIcon} term="animation.icon4" />
                                <div className="path-wrapper">
                                    <img src={walkPath} aria-hidden="true" alt="Animated path" style={{ width, height }} />
                                </div>
                            </>}
                        </AutoSizer>
                    </div>
                </div> */}
            </Section>

            <footer
                className={promptHidden ? 'hidden' : ''}
                onClick={() => window.scrollTo({ top: window.innerHeight - 100, left: 0, behavior: 'smooth' })}
            >
                <FiChevronDown />
            </footer>
        </div>
    );
});
